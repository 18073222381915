<template>
  <div>
    <v-dialog v-model="viewDialog" max-width="1000" persistent scrollable>
      <v-card>
        <v-card-title> {{ name }} services</v-card-title>
        <v-card-text>
          <v-data-iterator
              :items="labList"
              :items-per-page="3"
              hide-default-footer
              item-key="name"
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                    v-for="item in items"
                    :key="item.id"
                    cols="12"
                    lg="4"
                    md="6"
                    sm="12"
                >
                  <v-card outlined>
                    <v-toolbar dense flat style="padding: 4px 0 0">
                      <v-toolbar-title>
                        {{ item.name | capitalize }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-avatar size="36">
                        <img :src="item.image">
                      </v-avatar>
                    </v-toolbar>
                    <v-card-title>

                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div class="my_text_list">
                        <ul>
                          <li>
                            <div class="title">pricing</div>
                            <div class="text">{{ currency(item.pricing) }}</div>
                          </li>
                          <li>
                            <div class="title">description</div>
                            <div class="text">{{ item.description }}</div>
                          </li>
                        </ul>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js";

export default {
  props: {
    viewDialog: Boolean,
    myId: {type: Number, default: 0},
    medic: {type: Number, default: 0},
    name: {type: String, default: null},
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    labList: [],
    headers: [
      {text: "services offered", value: "servicesName"},
      {text: "description", value: "description"},
      {text: "image", value: "picture"},
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      console.log('myId:', this.myId)
      Restful.subscribers.subservice
          .list(this.myId)
          .then((response) => {
            console.log(response);
            this.labList = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeView", payload);
    },
  },
};
</script>
