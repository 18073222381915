<template>
  <v-dialog v-model="detailDialog" persistent>
    <v-card>
      <v-card-title>details more information</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="view" :items-per-page="5">
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "../../../../mixins/utils";

export default {
  props: {
    detailDialog: Boolean,
    myId: { type: String },
  },
  mixins: [Utils],
  data() {
    return {
      view: [],
      headers: [
        { text: "medicine", value: "medicine_name" },
        { text: "brand", value: "medi_specify" },
        { text: "route", value: "route_name" },
        { text: "frequency", value: "frequency_name_name" },
        { text: "strength", value: "strength" },
        { text: "duration", value: "duration" },
        { text: "directions", value: "directions" },
        { text: "quantity", value: "quantity" },
      ],
    };
  },
  created() {
    this.detail();
  },
  methods: {
    detail() {
      let id = parseInt(this._decode(this.myId));
      Restful.medics.treatment.prescription
        .list(id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data.prescriptions;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeDetail", payload);
    },
  },
};
</script>
