<template>
  <div>
    <v-dialog v-model="viewDialog" max-width="1000" persistent>
      <v-card>
        <v-card-title>
          {{ name }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="mediList"
            :loading="loading"
            :sort-by="['id']"
            :sort-desc="[true]"
            loader-height="1"
          >
            <template v-slot:item.drug="{ item }">
              {{ item.drug | capitalize }} ( {{ item.strength }})
            </template>
            <template v-slot:item.brand_name="{ item }">
              {{ item.brand_name | capitalize }}
            </template>

            <template v-slot:item.price="{ item }">
              {{ currency(item.price) }}
            </template>

            <template v-slot:item.quantity="{ item }">
              {{ comma(item.quantity) }}
            </template>
            <template v-slot:item.cost="{ item }">
              {{ currency(item.cost) }}
            </template>
            <template v-slot:item.sub_total="{ item }">
              {{ currency(item.sub_total) }}
            </template>

            <template v-slot:item.manufacturer="{ item }">
              {{ item.manufacturer | capitalize }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils.js";
// import Restful from "../../../services/RestFul.js";

export default {
  props: {
    viewDialog: Boolean,
    myId: { type: Number, default: 0 },
    medic: { type: Number, default: 0 },
    name: { type: String, default: null },
    myData: { type: String },
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    mediList: [],
    headers: [
      { text: "brand", value: "brand_name" },
      { text: "description", value: "brand_description" },
      { text: "manufacturer", value: "manufacturer" },
      { text: "quantity", value: "quantity" },
      { text: "unit cost", value: "price" },
      { text: "sub total", value: "sub_total" },
    ],
  }),
  created() {
    // this.find();
    this.mediList = JSON.parse(this.myData);
  },
  methods: {
    // find() {
    //   this.loading = true;
    //   let medics_available_timesid = this.medic;
    //   let subscriberSubsidiariesid = this.myId;
    //   let data = { medics_available_timesid, subscriberSubsidiariesid };
    //   console.log("data", data);
    //   let self = this;
    //   Restful.medics.treatment.transact.prospect.prescribe
    //     .detailMedic(data)
    //     .then((response) => {
    //       console.log(response.data);
    //       this.mediList = response.data;
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //       self.loading = false;
    //     });
    // },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
