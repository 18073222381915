<template>
  <div>
    <v-dialog v-model="chatDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          Chat
          <span style="text-transform: lowercase; padding: 0px 5px">with</span>
          {{ name }}
        </v-card-title>
        <v-card-text>
          Are you sure you want to permanently remove this schedule for
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="red" v-on="on" @click="closeDialog">
                NO
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="blue" v-on="on" @click="submit">
                yes
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    chatDialog: Boolean,
    myId: { type: Number, default: 0 },
    medic: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  data: () => ({}),
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeChat", payload);
    },
    submit() {
      let payload = { id: this.myId, state: false };
      this.$emit("submitChat", payload);
    },
  },
};
</script>
