<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="goBack" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar
        color="rgb(91, 104, 49)"
        dark
        dense
        flat
        rounded
        style="margin-bottom: 5px"
      >
        <v-toolbar-title style="color: white">
          Required By {{ _decode(subscriber) | capitalize }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="links"
          background-color="transparent"
          color="yellow lighten-5"
          group
          tile
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn dark value="left" @click="prescribe" v-on="on">
                <v-icon small>mdi-pill-multiple</v-icon>
                Prescription
              </v-btn>
            </template>
            <span>Prescription Request</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn value="right" @click="labs" v-on="on">
                <v-icon small>mdi-flask-round-bottom</v-icon>
                Diagnostics
              </v-btn>
            </template>
            <span>Diagnostics Request</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-toolbar>

      <div v-show="showPrescribe">
        <FollowPrescription v-show="showPrescribe" :id="id" />
      </div>
      <div v-show="showLabs">
        <FollowDiagnostics v-show="showLabs" :id="id" />
      </div>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../../mixins/windowscroll";
import Util from "../../../mixins/utils.js";
import FollowDiagnostics from "./follow_diagnostics.vue";
import FollowPrescription from "./follow_prescription.vue";

export default {
  name: "followUp",
  props: {
    id: { type: String },
    subscriber: { type: String },
    date: { type: String },
  },
  components: { FollowDiagnostics, FollowPrescription },
  mixins: [Util, WinScroll("position")],
  data: () => ({
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "patient.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "patient.appointments" },
      },
      {
        exact: true,
        link: true,
        text: "Followup Requests",
        to: { name: "patient.appointments.followup" },
      },
    ],
    links: null,
    followPages: "prescribe",
    showPrescribe: true,
    showLabs: false,
  }),
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  methods: {
    labs() {
      this.showPrescribe = false;
      this.showLabs = true;
    },
    prescribe() {
      this.showPrescribe = true;
      this.showLabs = false;
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    menuLink() {
      this.$emit("sidebar");
    },
  },
};
</script>

<style scoped></style>
