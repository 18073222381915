<template>
  <div>
    <v-dialog v-model="buyDialog" persistent max-width="600">
      <v-card>
        <v-card-title>Offer to {{ name }}</v-card-title>
        <v-card-subtitle style="margin-top: 5px">
          There are certain reasons why an offer has to be initially made to the
          seller. The following are some of the obvious reasons;
        </v-card-subtitle>
        <v-card-text>
          <ol>
            <li>
              A particular medication kind may repeat itself in the submitted
              list.
            </li>
            <li>
              You may not be able to purchase all of them at the same time now.
            </li>
            <li>
              The pharmacist being a proffessional may be able to give you
              further advice on what medication to purchase, based on the
              provided prescription.
            </li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="red" v-on="on" @click="closeDialog">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="blue" v-on="on" @click="submit">
                make offer now
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    buyDialog: Boolean,
    myId: { type: Number, default: 0 },
    medic: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  data: () => ({}),
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeBuy", payload);
    },
    submit() {
      let subscriber_subsidiariesid = this.myId;
      let medics_available_timesid = this.medic;
      let data = { subscriber_subsidiariesid, medics_available_timesid };
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitBuy", payload);
    },
  },
};
</script>
