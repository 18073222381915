<template>
  <div>
    <v-dialog v-model="responseDialog" max-width="1000" persistent>
      <v-card :loading="loading" loader-height="1">
        <v-card-title>View Response Offer</v-card-title>
        <v-card-text>
          <!-- start -->
          <v-data-table
              :headers="headers"
              :items="responseList"
              :items-per-page="5"
              :sort-desc="[true]">
            <template v-slot:item.pricing="{ item }">
              {{ currency(item.pricing) }}
            </template>
          </v-data-table>
          <!-- end -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                NO
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "@/services/RestFul";

export default {
  props: {
    responseDialog: Boolean,
    myId: {type: Number},
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    responseList: [],
    headers: [
      {text: "name", value: "service_name"},
      {text: "detail", value: "comment"},
      {text: "pricing", value: "pricing"},
      {text: "instructions", value: "instructions"},
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      console.log("lab detail");
      this.loading = true;
      let self = this;
      Restful.medics.treatment.transact.prospect.lab
          .responseDetail(this.myId)
          .then((response) => {
            console.log(response);
            this.responseList = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      console.log('pay', payload)
      this.$emit("closeResponse", payload);
    },
  },
};
</script>
