<template>
  <div>
    <v-dialog v-model="viewRespDialog" persistent>
      <v-card>
        <v-card-title>
          {{ name }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="mediList"
            :loading="loading"
            :sort-by="['id']"
            :sort-desc="[true]"
            loader-height="1"
          >
            <template v-slot:item.quantity="{ item }">
              {{ comma(item.quantity) }}
            </template>
            <template v-slot:item.medical.price="{ item }">
              {{ currency(item.medical.price) }}
            </template>
            <template v-slot:item.medical.total_cost="{ item }">
              {{ currency(item.medical.total_cost) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils.js";
import Restful from "../../../services/RestFul.js";

export default {
  props: {
    viewRespDialog: Boolean,
    medic: { type: Number, default: 0 },
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    mediList: [],
    headers: [
      { text: "brand", value: "medical.brand_name" },
      { text: "description", value: "medical.brand_description" },
      { text: "manufacturer", value: "medical.manufacturer" },
      { text: "quantity", value: "quantity" },
      { text: "unit cost", value: "medical.price" },
      { text: "sub total", value: "medical.total_cost" },
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let medics_available_timesid = this.medic;
      let subscriberSubsidiariesid = this.myId;
      let data = { medics_available_timesid, subscriberSubsidiariesid };
      console.log("data", data);
      let self = this;
      Restful.medics.treatment.transact.prospect.prescribe
        .detailMedic(data)
        .then((response) => {
          console.log(response.data);
          this.mediList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeRespView", payload);
    },
  },
};
</script>
