<template>
  <div>
    <v-dialog v-model="buyDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>Offer to {{ name }}</v-card-title>
        <v-card-subtitle style="margin-top: 5px">
          There are certain reasons why an offer has to be initially made to the
          seller. The following are some of the obvious reasons;
        </v-card-subtitle>
        <v-card-text>
          <ol>
            <li>
              The request presented by the medical doctor would determine the
              services that the Laboratory can offer and thus it's cost.
            </li>
            <li>
              The Laboratory may have several option with their own pricing for
              the same kind of test.
            </li>
            <li>
              The Laboratory can understand better what the medical provider wants from the test.
            </li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="blue" small text @click="submit" v-on="on">
                make offer now
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils.js";

export default {
  props: {
    buyDialog: Boolean,
    myId: {type: Number, default: 0},
    medic: {type: String},
    name: {type: String, default: null},
  },
  mixins: [Utils],
  data: () => ({}),
  created() {
  },
  methods: {
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeBuy", payload);
    },
    submit() {
      let subscriberSubsidiariesid = this.myId;
      let medics_available_timesid = parseInt(this._decode(this.medic));
      let data = {subscriberSubsidiariesid, medics_available_timesid};
      let payload = {id: this.myId, state: false, data: data};
      this.$emit("submitBuy", payload);
    },
  },
};
</script>
