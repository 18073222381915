<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title> Diagnostics Service </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs v-model="labTabs" right show-arrows style="max-width: 50%">
        <v-tab key="select"> select </v-tab>
        <v-tab key="response"> response </v-tab>
      </v-tabs>
      <v-btn color="primary" small text @click.stop="detailDialog = true">
        details
      </v-btn>
      <LabDetail
        :detailDialog="detailDialog"
        :myId="id"
        @closeDetail="closeDetail"
      />

      <v-btn color="primary" icon text @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs-items
      v-model="labTabs"
      style="background-color: #e9f0f6; margin-top: 4px"
    >
      <v-tab-item key="select">
        <v-row style="margin: 10px 0">
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-text>
                <v-select
                  v-model="find.regionsId"
                  :items="states"
                  item-text="name"
                  item-value="id"
                  label="select region/state"
                  return-object
                ></v-select>
                <v-select
                  v-model="find.districtsId"
                  :disabled="disabled"
                  :items="districtlist"
                  clearable
                  item-text="name"
                  item-value="id"
                  label="select district"
                ></v-select>
                <br />
              </v-card-text>
              <v-card-actions>
                <div
                  style="
                    display: block;
                    width: 100%;
                    text-align: right;
                    padding: 0 4px 0 0;
                    font-size: 0.8em;
                    color: #b0b0b0;
                  "
                >
                  {{ listSize > 0 ? listSize + " Center Count" : "" }}
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" style="padding-top: 23px">
            <v-data-iterator :items="pharmaList">
              <template v-slot:default="{ items }">
                <v-row>
                  <v-col
                    v-for="item in items"
                    :key="item.id"
                    cols="12"
                    sm="12"
                    style="padding: 1px 0 0"
                  >
                    <v-card outlined style="padding-bottom: 3px">
                      <v-card-actions class="pricing">
                        <ul class="pricingright">
                          <li class="title">{{ item.name }}</li>
                          <li class="subtitle">
                            {{ item.business_name }}
                          </li>
                          <li class="subtitle">
                            {{ item.physical_location }}
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="success"
                                  fab
                                  icon
                                  small
                                  text
                                  v-on="on"
                                >
                                  <v-icon>mdi-earth</v-icon>
                                </v-btn>
                              </template>
                              <span> View Location on map </span>
                            </v-tooltip>
                          </li>
                          <li class="subtitle"></li>
                          <li>
                            <v-rating
                              :value="item.rating"
                              dense
                              readonly
                              small
                            ></v-rating>
                          </li>
                        </ul>
                        <v-spacer></v-spacer>
                        <ul class="pricingleft">
                          <li class="notify">
                            <v-row>
                              <v-col cols="12">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      :disabled="item.is_requested"
                                      color="success"
                                      small
                                      text
                                      v-on="on"
                                      @click.stop="
                                        $set(buyDialog, item.id, true)
                                      "
                                    >
                                      request
                                    </v-btn>
                                  </template>
                                  <span>
                                    Make an Offer to the Laboratory Service
                                  </span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                            <PrecribeBuy
                              v-if="buyDialog[item.id]"
                              :buyDialog="buyDialog[item.id]"
                              :medic="id"
                              :myId="item.id"
                              :name="item.name"
                              @closeBuy="closeBuy"
                              @submitBuy="submitBuy"
                            />
                          </li>
                          <li>
                            <v-row>
                              <v-col>
                                <v-btn
                                  :disabled="
                                    checkLabStatus.accept ? false : true
                                  "
                                  color="success"
                                  text
                                  x-small
                                >
                                  Status
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      color="info"
                                      text
                                      x-small
                                      v-on="on"
                                      @click.stop="
                                        $set(viewDialog, item.id, true)
                                      "
                                    >
                                      view
                                    </v-btn>
                                  </template>
                                  <span>View Laboratory Services</span>
                                </v-tooltip>
                                <LabView
                                  v-if="viewDialog[item.id]"
                                  :medic="item.medics_available_timesid"
                                  :myId="item.id"
                                  :name="item.name"
                                  :viewDialog="viewDialog[item.id]"
                                  @closeView="closeView"
                                />
                              </v-col>
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      color="indigo"
                                      text
                                      x-small
                                      v-on="on"
                                      @click.stop="
                                        $set(chatDialog, item.id, true)
                                      "
                                    >
                                      chat
                                    </v-btn>
                                  </template>
                                  <span>Chat with Seller</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </li>
                        </ul>
                        <br />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
            <!--            <LabMakeRequest/>-->
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="response">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="responseHeaders"
              :items="myResponseList"
              :items-per-page="5"
              :sort-desc="[true]"
            >
              <template v-slot:item.business_name="{ item }">
                {{ item.business_name | capitalize }}
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name | capitalize }}
              </template>

              <template v-slot:item.accept="{ item }">
                {{ item.accept ? "Yes" : "No" }}
              </template>

              <template v-slot:item.appointment_date="{ item }">
                {{
                  item.appointment_date === null
                    ? ""
                    : item.appointment_date | myDating
                }}
              </template>

              <template v-slot:item.price="{ item }">
                {{ item.price > 0 ? currency(item.price) : "" }}
              </template>

              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="success"
                      text
                      x-small
                      v-on="on"
                      @click.stop="
                        $set(responseDialog, item.laboratory_requestid, true)
                      "
                    >
                      view details
                    </v-btn>
                  </template>
                  <span>View provider's offer response</span>
                </v-tooltip>
                <LabResponseView
                  v-if="responseDialog[item.laboratory_requestid]"
                  :myId="item.laboratory_requestid"
                  :responseDialog="responseDialog[item.laboratory_requestid]"
                  @closeResponse="closeResponse"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="item.price > 0 ? false : true"
                      color="info"
                      text
                      x-small
                      @click="checkout(item.id, item.price)"
                      v-on="on"
                    >
                      pay now
                    </v-btn>
                  </template>
                  <span>Pay for the service to confirm appointment</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../../mixins/utils";
import LabDetail from "../../../components/patient/request/lab/detail.vue";
import LabView from "../../../components/appointments/lab/view.vue";
import PrecribeBuy from "../../../components/appointments/lab/buy.vue";
import LabResponseView from "../../../components/appointments/lab/response.vue";

export default {
  name: "follow_diagnostics",
  props: { id: { type: String } },
  mixins: [Util],
  components: { LabDetail, LabView, PrecribeBuy, LabResponseView },
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    labTabs: null,
    detailDialog: false,
    responseDialog: {},
    states: [],
    districtlist: [],
    find: {
      regionsId: 0,
      districtsId: 0,
    },
    disabled: true,
    headers: [
      { text: "subscriber", value: "subscriber.business_name" },
      { text: "rating", value: "subscriber.ratings", align: "center" },
      { text: "action", value: "action", align: "center" },
    ],
    pharmaList: [],
    reviewDialog: {},
    makeDialog: {},
    isEmpty: false,
    buyDialog: {},
    viewDialog: {},
    chatDialog: {},
    myResponseList: [],
    responseHeaders: [
      { text: "business name", value: "business_name" },
      { text: "name", value: "name" },
      { text: "accepted", value: "accept", align: "center" },
      { text: "price", value: "price" },
      { text: "appointment date", value: "appointment_date" },
      { text: "action", value: "action", align: "center" },
    ],
    checkLabStatus: {},
    listSize: 0,
  }),
  created() {
    this.finished();
    this.regions();
    this.requests();
    this.myResponses();
  },
  watch: {
    "find.regionsId": function (i) {
      console.log(i);
      if (i.id > 0) {
        this.districts(i.id);
        this.disabled = false;
      }
    },
    "find.districtsId": function (a) {
      console.log(a);
      if (a > 0) {
        this.getList();
      }
    },
    disRequest: function (a) {
      console.log(a);
    },
  },
  methods: {
    closeDetail(p) {
      this.detailDialog = p.state;
      // this.$set(this.detailDialog, p.id, p.state);
    },
    finished() {
      this.loading = true;
      let id = parseInt(this._decode(this.id));
      Restful.medics.treatment.labs
        .finished(id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.isEmpty = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    regions() {
      Restful.general.regions
        .byCountry(1)
        .then((response) => {
          console.log(response.data);
          this.states = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    districts(id) {
      Restful.general.district
        .byRegion(id)
        .then((response) => {
          console.log(response.data);
          this.districtlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getList() {
      let countryid = 1;
      let regionsid = this.find.regionsId.id;
      let districtsid = this.find.districtsId;
      let data = { countryid, regionsid, districtsid };
      console.log("data", data);
      Restful.medics.treatment.transact.prospect.lab
        .findMedics(data)
        .then((response) => {
          console.log(response.data);
          this.pharmaList = response.data;
          this.listSize = response.data.length;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    checkStatus(ss, isDone) {
      let done = isDone;
      let subscriberSubsidiariesid = ss;
      let d = { done, subscriberSubsidiariesid };
      console.log("data: ", d);
      Restful.medics.treatment.transact.prospect.lab
        .checkStatus(d)
        .then((response) => {
          console.log(response.data);
          this.checkLabStatus = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    requests() {
      let id = parseInt(this._decode(this.id));
      Restful.medics.treatment.transact.prospect.lab
        .requests(id)
        .then((response) => {
          console.log(response.data);
          this.requestlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    refresh() {
      this.myResponses();
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeMake(p) {
      this.$set(this.makeDialog, p.id, p.state);
    },
    submitMake(p) {
      this.$set(this.makeDialog, p.id, p.state);
    },
    closeBuy(p) {
      this.$set(this.buyDialog, p.id, p.state);
    },
    submitBuy(p) {
      console.log("p:", p);
      this.snack.bar = true;
      console.log("in", p);
      let self = this;
      Restful.medics.treatment.transact.prospect.lab
        .create(p.data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.finished();
          this.regions();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response;
        });
      this.$set(this.buyDialog, p.id, p.state);
    },
    myResponses() {
      let self = this;
      let medics_available_timesid = parseInt(this._decode(this.id));
      let d = { medics_available_timesid };
      Restful.medics.treatment.transact.prospect.lab
        .responses(d)
        .then((response) => {
          console.log(response);
          this.myResponseList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response;
        });
    },
    checkout(myId, total) {
      let medicsAvailableId = parseInt(this._decode(this.id));
      let type = "labs";
      let price = total;
      let id = myId;
      let data = { id, medicsAvailableId, type, price };
      console.log("data: ", data);
      let encode = this._encode(JSON.stringify(data));
      this.$router.push({ name: "pay.now", params: { id: encode } });
    },
    closeResponse(p) {
      this.$set(this.responseDialog, p.id, p.state);
    },
  },
};
</script>

<style scoped></style>
